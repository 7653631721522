var render = function render(){
  var _vm$selectedGroup, _vm$selectedCategoryR;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-form', {
    attrs: {
      "i18nNamespace": `form.base.${_vm.type}`,
      "fallbackI18nNamespace": "form.base.resource",
      "enabledFields": Object.keys(_vm.feedback),
      "errors": _vm.feedback
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nazwa zasobu"
    },
    model: {
      value: _vm.resource.name,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "name", $$v);
      },
      expression: "resource.name"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "register_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "GLE00000"
    },
    model: {
      value: _vm.resource.register_number,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "register_number", $$v);
      },
      expression: "resource.register_number"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "serial_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": `00000000/${_vm.year}`
    },
    model: {
      value: _vm.resource.serial_number,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "serial_number", $$v);
      },
      expression: "resource.serial_number"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "cadre_employee_id"
    }
  }, [_c('cadre-employee-picker', {
    model: {
      value: _vm.resource.cadre_employee_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "cadre_employee_id", $$v);
      },
      expression: "resource.cadre_employee_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "base_category_id"
    },
    scopedSlots: _vm._u([{
      key: "children",
      fn: function () {
        return [_c('div', {
          staticClass: "provider-date"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "base_producer_model_id"
          }
        }, [_c('base-producer-model-picker', {
          attrs: {
            "excluded": _vm.excludeOptionByType
          },
          model: {
            value: _vm.resource.base_producer_model_id,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "base_producer_model_id", $$v);
            },
            expression: "resource.base_producer_model_id"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "production_date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": _vm.year
          },
          model: {
            value: _vm.resource.production_date,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "production_date", $$v);
            },
            expression: "resource.production_date"
          }
        })], 1)], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "field": "base_technology_id"
          }
        }, [_c('base-technology-picker', {
          attrs: {
            "multiple": "",
            "closeOnSelect": false
          },
          model: {
            value: _vm.resource.base_technology_id,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "base_technology_id", $$v);
            },
            expression: "resource.base_technology_id"
          }
        })], 1), _c('div', {
          staticClass: "provider-date"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "fuel_card"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "000000000"
          },
          model: {
            value: _vm.resource.fuel_card,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "fuel_card", $$v);
            },
            expression: "resource.fuel_card"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "fuel_card_pin"
          }
        }, [_c('b-input-group', {
          scopedSlots: _vm._u([{
            key: "prepend",
            fn: function () {
              return [_c('div', {
                staticClass: "fuel-card-pin-icon"
              }, [_c('feather-icon', {
                attrs: {
                  "icon": "KeyIcon"
                }
              })], 1)];
            },
            proxy: true
          }])
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "00000"
          },
          model: {
            value: _vm.resource.fuel_card_pin,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "fuel_card_pin", $$v);
            },
            expression: "resource.fuel_card_pin"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('base-category-picker', {
    attrs: {
      "excluded": _vm.excludeOptionByType
    },
    on: {
      "pick": function (category) {
        return _vm.selectedCategory = category;
      }
    },
    model: {
      value: _vm.resource.base_category_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "base_category_id", $$v);
      },
      expression: "resource.base_category_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "base_status_id"
    }
  }, [_c('base-status-picker', {
    attrs: {
      "excluded": _vm.excludeOptionByType
    },
    model: {
      value: _vm.resource.base_status_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "base_status_id", $$v);
      },
      expression: "resource.base_status_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "company_branch_id"
    }
  }, [_c('core-branch-picker', {
    attrs: {
      "placeholder": "Wybierz oddział z listy"
    },
    model: {
      value: _vm.resource.company_branch_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "company_branch_id", $$v);
      },
      expression: "resource.company_branch_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "gear_type"
    }
  }, [_c('base-resource-extra-field-picker', {
    attrs: {
      "excluded": function (option) {
        return option.field.slug !== _vm.baseResourceExtraField.GearType;
      },
      "placeholder": "Wybierz typ skrzyni z listy"
    },
    model: {
      value: _vm.resource.gear_type,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "gear_type", $$v);
      },
      expression: "resource.gear_type"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "fuel_type"
    }
  }, [_c('base-resource-extra-field-picker', {
    attrs: {
      "excluded": function (option) {
        return option.field.slug !== _vm.baseResourceExtraField.FuelType;
      },
      "placeholder": "Wybierz rodzaj paliwa z listy"
    },
    model: {
      value: _vm.resource.fuel_type,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "fuel_type", $$v);
      },
      expression: "resource.fuel_type"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "engine"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Wprowadź silnik"
    },
    model: {
      value: _vm.resource.engine,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "engine", $$v);
      },
      expression: "resource.engine"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "engine_power_km"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "0"
    },
    model: {
      value: _vm.resource.engine_power_km,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "engine_power_km", $$v);
      },
      expression: "resource.engine_power_km"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "engine_power_kw"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "0"
    },
    model: {
      value: _vm.resource.engine_power_kw,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "engine_power_kw", $$v);
      },
      expression: "resource.engine_power_kw"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "engine_size_cc"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "0"
    },
    model: {
      value: _vm.resource.engine_size_cc,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "engine_size_cc", $$v);
      },
      expression: "resource.engine_size_cc"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "drivetrain"
    }
  }, [_c('base-resource-extra-field-picker', {
    attrs: {
      "excluded": function (option) {
        return option.field.slug !== _vm.baseResourceExtraField.Drivetrain;
      },
      "placeholder": "Wybierz napęd z listy"
    },
    model: {
      value: _vm.resource.drivetrain,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "drivetrain", $$v);
      },
      expression: "resource.drivetrain"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "tires"
    }
  }, [_c('base-resource-extra-field-picker', {
    attrs: {
      "excluded": function (option) {
        return option.field.slug !== _vm.baseResourceExtraField.Tires;
      },
      "placeholder": "Wybierz rodzaj opony z listy"
    },
    model: {
      value: _vm.resource.tires,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "tires", $$v);
      },
      expression: "resource.tires"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "car_segment"
    }
  }, [_c('base-resource-extra-field-picker', {
    attrs: {
      "excluded": function (option) {
        return option.field.slug !== _vm.baseResourceExtraField.CarSegment;
      },
      "placeholder": "Wybierz klase samochodu z listy"
    },
    model: {
      value: _vm.resource.car_segment,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "car_segment", $$v);
      },
      expression: "resource.car_segment"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "base_resource_daily_cost"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "PLN"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "100"
    },
    model: {
      value: _vm.resource.base_resource_daily_cost,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "base_resource_daily_cost", $$v);
      },
      expression: "resource.base_resource_daily_cost"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "base_resource_activity.base_group_id"
    }
  }, [_c('base-group-picker', {
    on: {
      "pick": function (group) {
        return _vm.selectedGroup = group;
      }
    },
    model: {
      value: _vm.resource.base_resource_activity.base_group_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource.base_resource_activity, "base_group_id", $$v);
      },
      expression: "resource.base_resource_activity.base_group_id"
    }
  })], 1), _c('vs-form-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedGroup && ((_vm$selectedGroup = _vm.selectedGroup) === null || _vm$selectedGroup === void 0 ? void 0 : _vm$selectedGroup.slug) !== 'wlasne',
      expression: "selectedGroup && selectedGroup?.slug !== 'wlasne'"
    }],
    attrs: {
      "field": "base_resource_activity.base_resource_collaboration.crm_company_id",
      "integration": "crm_address_relation"
    },
    scopedSlots: _vm._u([_vm.resource.base_resource_activity.base_resource_collaboration.crm_company_id ? {
      key: "children",
      fn: function () {
        return [_c('div', {
          staticClass: "provider-date"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "base_resource_activity.base_resource_collaboration.start_date"
          }
        }, [_c('vs-datepicker', {
          model: {
            value: _vm.resource.base_resource_activity.base_resource_collaboration.start_date,
            callback: function ($$v) {
              _vm.$set(_vm.resource.base_resource_activity.base_resource_collaboration, "start_date", $$v);
            },
            expression: "resource.base_resource_activity.base_resource_collaboration.start_date"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "base_resource_activity.base_resource_collaboration.end_date"
          }
        }, [_c('vs-datepicker', {
          model: {
            value: _vm.resource.base_resource_activity.base_resource_collaboration.end_date,
            callback: function ($$v) {
              _vm.$set(_vm.resource.base_resource_activity.base_resource_collaboration, "end_date", $$v);
            },
            expression: "resource.base_resource_activity.base_resource_collaboration.end_date"
          }
        })], 1)], 1), _c('div', {
          staticClass: "provider-date"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "reservation_number"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Wpisz nr rezerwacji"
          },
          model: {
            value: _vm.resource.reservation_number,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "reservation_number", $$v);
            },
            expression: "resource.reservation_number"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "lease_agreement_number"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Wpisz nr umowy"
          },
          model: {
            value: _vm.resource.lease_agreement_number,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "lease_agreement_number", $$v);
            },
            expression: "resource.lease_agreement_number"
          }
        })], 1)], 1), _c('div', {
          staticClass: "provider-date"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "max_monthly_mileage"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Liczba kilometrów miesięczna"
          },
          model: {
            value: _vm.resource.max_monthly_mileage,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "max_monthly_mileage", $$v);
            },
            expression: "resource.max_monthly_mileage"
          }
        })], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "max_total_mileage"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Liczba kilometrów całkowita"
          },
          model: {
            value: _vm.resource.max_total_mileage,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "max_total_mileage", $$v);
            },
            expression: "resource.max_total_mileage"
          }
        })], 1)], 1), _c('div', {
          staticClass: "provider-date"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "notice_period"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Podaj okres wypowiedzenia"
          },
          model: {
            value: _vm.resource.notice_period,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "notice_period", $$v);
            },
            expression: "resource.notice_period"
          }
        })], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('crm-provider-picker', {
    attrs: {
      "reduce": function (option) {
        return option.id;
      },
      "clearable": ""
    },
    model: {
      value: _vm.resource.base_resource_activity.base_resource_collaboration.crm_company_id,
      callback: function ($$v) {
        _vm.$set(_vm.resource.base_resource_activity.base_resource_collaboration, "crm_company_id", $$v);
      },
      expression: "resource.base_resource_activity.base_resource_collaboration.crm_company_id"
    }
  })], 1), _vm.selectedCategory && _vm.selectedCategory.be_reported ? _c('vs-form-group', {
    attrs: {
      "field": "mth",
      "forcedLabel": (_vm$selectedCategoryR = _vm.selectedCategoryReportUnit) === null || _vm$selectedCategoryR === void 0 ? void 0 : _vm$selectedCategoryR.name,
      "hideParentFormGroup": ""
    },
    scopedSlots: _vm._u([{
      key: "children",
      fn: function () {
        var _vm$selectedCategoryR2, _vm$selectedCategoryR3, _vm$selectedCategoryR4, _vm$selectedCategoryR5;
        return [_c('div', {
          staticClass: "provider-date"
        }, [_c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "meter_status_buy",
            "i18nArgs": {
              unit: (_vm$selectedCategoryR2 = _vm.selectedCategoryReportUnit) === null || _vm$selectedCategoryR2 === void 0 ? void 0 : _vm$selectedCategoryR2.name
            }
          }
        }, [_c('b-input-group', {
          attrs: {
            "append": (_vm$selectedCategoryR3 = _vm.selectedCategoryReportUnit) === null || _vm$selectedCategoryR3 === void 0 ? void 0 : _vm$selectedCategoryR3.code
          }
        }, [_c('b-form-input', {
          model: {
            value: _vm.resource.meter_status_buy,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "meter_status_buy", $$v);
            },
            expression: "resource.meter_status_buy"
          }
        })], 1)], 1), _c('vs-form-group', {
          attrs: {
            "labelCols": "12",
            "cols": "6",
            "field": "meter_status_card_index",
            "i18nArgs": {
              unit: (_vm$selectedCategoryR4 = _vm.selectedCategoryReportUnit) === null || _vm$selectedCategoryR4 === void 0 ? void 0 : _vm$selectedCategoryR4.name
            }
          }
        }, [_c('b-input-group', {
          attrs: {
            "append": (_vm$selectedCategoryR5 = _vm.selectedCategoryReportUnit) === null || _vm$selectedCategoryR5 === void 0 ? void 0 : _vm$selectedCategoryR5.code
          }
        }, [_c('b-form-input', {
          model: {
            value: _vm.resource.meter_status_card_index,
            callback: function ($$v) {
              _vm.$set(_vm.resource, "meter_status_card_index", $$v);
            },
            expression: "resource.meter_status_card_index"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 3841341093)
  }) : _vm._e(), _c('vs-form-group', {
    attrs: {
      "field": "gps_panel"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "https://example.org/gps"
    },
    model: {
      value: _vm.resource.gps_panel,
      callback: function ($$v) {
        _vm.$set(_vm.resource, "gps_panel", $$v);
      },
      expression: "resource.gps_panel"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }