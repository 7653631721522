const defaultFields = {
  base_category_id: 'required',
  base_producer_model_id: 'required',
  production_date: 'required|min:4',
  base_status_id: 'required',
  base_resource_daily_cost: 'required',
  base_resource_activity: {
    base_group_id: 'required',
    base_resource_collaboration: {
      crm_company_id: '',
      start_date: 'required_if:base_resource_activity.base_resource_collaboration.crm_company_id',
      end_date: 'required_if:base_resource_activity.base_resource_collaboration.crm_company_id',
    },
  },
  serial_number: 'required',
  meter_status_buy: 'integer',
  meter_status_card_index: 'integer|min_value:@meter_status_buy',
};

const baseResourceFormValidations = {
  default: {
    ...defaultFields,
  },
  car: {
    ...defaultFields,
    register_number: 'required',
    cadre_employee_id: 'required',
    fuel_card: '',
    fuel_card_pin: '',
    gps_panel: '',
    gear_type: '',
    fuel_type: '',
    engine_power_km: '',
    engine_power_kw: '',
    engine_size_cc: '',
    engine: '',
    drivetrain: '',
    tires: '',
    car_segment: '',
    reservation_number: '',
    lease_agreement_number: '',
    notice_period: '',
    company_branch_id: '',
    max_monthly_mileage: '',
    max_total_mileage: '',
  },
  vehicle: {
    ...defaultFields,
    name: 'required',
    base_technology_id: '',
  },
  tool: {
    ...defaultFields,
    name: 'required',
    base_technology_id: '',
  },
};

export default baseResourceFormValidations;
