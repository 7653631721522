export const BaseResourceExtraField = {
  Engine: 'engine',
  GearType: 'gear_type',
  Drivetrain: 'drivetrain',
  FuelType: 'fuel_type',
  Tires: 'tires',
  CarSegment: 'car_segment',
  EnginePowerKm: 'engine_power_km',
  EnginePowerKw: 'engine_power_kw',
  EngineSizeCc: 'engine_size_cc',
  ReservationNumber: 'reservation_number',
  LeaseAgreementNumber: 'lease_agreement_number',
  NoticePeriod: 'notice_period',
};

export const BaseResourceExtraFieldType = {
  Input: 'input',
  Select: 'select',
};

export const BaseResourceExtraFields = {
  [BaseResourceExtraField.Engine]: BaseResourceExtraFieldType.Input,
  [BaseResourceExtraField.GearType]: BaseResourceExtraFieldType.Select,
  [BaseResourceExtraField.Drivetrain]: BaseResourceExtraFieldType.Select,
  [BaseResourceExtraField.FuelType]: BaseResourceExtraFieldType.Select,
  [BaseResourceExtraField.Tires]: BaseResourceExtraFieldType.Select,
  [BaseResourceExtraField.CarSegment]: BaseResourceExtraFieldType.Select,
  [BaseResourceExtraField.EnginePowerKm]: BaseResourceExtraFieldType.Input,
  [BaseResourceExtraField.EnginePowerKw]: BaseResourceExtraFieldType.Input,
  [BaseResourceExtraField.EngineSizeCc]: BaseResourceExtraFieldType.Input,
  [BaseResourceExtraField.ReservationNumber]: BaseResourceExtraFieldType.Input,
  [BaseResourceExtraField.LeaseAgreementNumber]: BaseResourceExtraFieldType.Input,
  [BaseResourceExtraField.NoticePeriod]: BaseResourceExtraFieldType.Input,
};
