import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchGroups } = useBaseApi();

export default defineStore('baseGroup', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('base.group', fetchGroups);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
