import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchIndexCategories } = useBaseApi();

export default defineStore('baseCategory', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('base.category', fetchIndexCategories);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
