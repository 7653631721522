<template>
  <vs-form
    :i18nNamespace="`form.base.${type}`"
    fallbackI18nNamespace="form.base.resource"
    :enabledFields="Object.keys(feedback)"
    :errors="feedback"
  >

    <vs-form-group field="name">
      <b-form-input
        v-model="resource.name"
        placeholder="Nazwa zasobu"
      />
    </vs-form-group>

    <vs-form-group field="register_number">
      <b-form-input
        v-model="resource.register_number"
        placeholder="GLE00000"
      />
    </vs-form-group>

    <vs-form-group field="serial_number">
      <b-form-input
        v-model="resource.serial_number"
        :placeholder="`00000000/${year}`"
      />
    </vs-form-group>

    <vs-form-group field="cadre_employee_id">
      <cadre-employee-picker
        v-model="resource.cadre_employee_id"
      />
    </vs-form-group>

    <vs-form-group field="base_category_id">
      <base-category-picker
        v-model="resource.base_category_id"
        @pick="(category) => selectedCategory = category"
        :excluded="excludeOptionByType"
      />

      <template #children>
        <div class="provider-date">
          <vs-form-group labelCols="12" cols="6" field="base_producer_model_id">
            <base-producer-model-picker
              v-model="resource.base_producer_model_id"
              :excluded="excludeOptionByType"
            />
          </vs-form-group>

          <vs-form-group labelCols="12" cols="6" field="production_date">
            <b-form-input
              v-model="resource.production_date"
              :placeholder="year"
            />
          </vs-form-group>
        </div>

        <vs-form-group labelCols="12" field="base_technology_id">
          <base-technology-picker
            multiple
            v-model="resource.base_technology_id"
            :closeOnSelect="false"
          />
        </vs-form-group>

        <div class="provider-date">
          <vs-form-group labelCols="12" cols="6" field="fuel_card">
            <b-form-input
              v-model="resource.fuel_card"
              placeholder="000000000"
            />
          </vs-form-group>

          <vs-form-group labelCols="12" cols="6" field="fuel_card_pin">
            <b-input-group>
              <template #prepend>
                <div class="fuel-card-pin-icon">
                  <feather-icon icon="KeyIcon" />
                </div>
              </template>
              <b-form-input
                placeholder="00000"
                v-model="resource.fuel_card_pin"
              />
            </b-input-group>
          </vs-form-group>
        </div>
      </template>

    </vs-form-group>

    <vs-form-group field="base_status_id">
      <base-status-picker
        v-model="resource.base_status_id"
        :excluded="excludeOptionByType"
      />
    </vs-form-group>

    <vs-form-group field="company_branch_id">
      <core-branch-picker
        v-model="resource.company_branch_id"
        placeholder="Wybierz oddział z listy"
      />
    </vs-form-group>

    <vs-form-group field="gear_type">
      <base-resource-extra-field-picker
        v-model="resource.gear_type"
        :excluded="
          (option) => option.field.slug !== baseResourceExtraField.GearType
        "
        placeholder="Wybierz typ skrzyni z listy"
      />
    </vs-form-group>

    <vs-form-group field="fuel_type">
      <base-resource-extra-field-picker
        v-model="resource.fuel_type"
        :excluded="
          (option) => option.field.slug !== baseResourceExtraField.FuelType
        "
        placeholder="Wybierz rodzaj paliwa z listy"
      />
    </vs-form-group>

    <vs-form-group field="engine">
      <b-form-input v-model="resource.engine" placeholder="Wprowadź silnik" />
    </vs-form-group>

    <vs-form-group field="engine_power_km">
      <b-form-input v-model="resource.engine_power_km" placeholder="0" />
    </vs-form-group>

    <vs-form-group field="engine_power_kw">
      <b-form-input v-model="resource.engine_power_kw" placeholder="0" />
    </vs-form-group>

    <vs-form-group field="engine_size_cc">
      <b-form-input v-model="resource.engine_size_cc" placeholder="0" />
    </vs-form-group>

    <vs-form-group field="drivetrain">
      <base-resource-extra-field-picker
        v-model="resource.drivetrain"
        :excluded="
          (option) => option.field.slug !== baseResourceExtraField.Drivetrain
        "
        placeholder="Wybierz napęd z listy"
      />
    </vs-form-group>

    <vs-form-group field="tires">
      <base-resource-extra-field-picker
        v-model="resource.tires"
        :excluded="
          (option) => option.field.slug !== baseResourceExtraField.Tires
        "
        placeholder="Wybierz rodzaj opony z listy"
      />
    </vs-form-group>

    <vs-form-group field="car_segment">
      <base-resource-extra-field-picker
        v-model="resource.car_segment"
        :excluded="
          (option) => option.field.slug !== baseResourceExtraField.CarSegment
        "
        placeholder="Wybierz klase samochodu z listy"
      />
    </vs-form-group>

    <vs-form-group field="base_resource_daily_cost">
      <b-input-group append="PLN">
        <b-form-input
          placeholder="100"
          v-model="resource.base_resource_daily_cost"
        />
      </b-input-group>
    </vs-form-group>

    <vs-form-group field="base_resource_activity.base_group_id">
      <base-group-picker
        v-model="resource.base_resource_activity.base_group_id"
        @pick="(group) => selectedGroup = group"
      />
    </vs-form-group>

    <vs-form-group
      field="base_resource_activity.base_resource_collaboration.crm_company_id"
      integration="crm_address_relation"
      v-show="selectedGroup && selectedGroup?.slug !== 'wlasne'"
    >
      <crm-provider-picker
        v-model="resource.base_resource_activity.base_resource_collaboration.crm_company_id"
        :reduce="(option) => option.id"
        clearable
      />

      <template #children
        v-if="resource.base_resource_activity.base_resource_collaboration.crm_company_id"
      >
        <div
          class="provider-date"
        >
          <vs-form-group
            labelCols="12"
            cols="6"
            field="base_resource_activity.base_resource_collaboration.start_date"
          >
            <vs-datepicker
              v-model="resource.base_resource_activity.base_resource_collaboration.start_date"
            />
          </vs-form-group>

          <vs-form-group
            labelCols="12"
            cols="6"
            field="base_resource_activity.base_resource_collaboration.end_date"
          >
            <vs-datepicker
              v-model="resource.base_resource_activity.base_resource_collaboration.end_date"
            />
          </vs-form-group>
        </div>

        <div
          class="provider-date"
        >
          <vs-form-group
            labelCols="12"
            cols="6"
            field="reservation_number"
          >
            <b-form-input v-model="resource.reservation_number" placeholder="Wpisz nr rezerwacji" />
          </vs-form-group>

          <vs-form-group
            labelCols="12"
            cols="6"
            field="lease_agreement_number"
          >
            <b-form-input v-model="resource.lease_agreement_number" placeholder="Wpisz nr umowy" />
          </vs-form-group>
        </div>

        <div
          class="provider-date"
        >
          <vs-form-group
            labelCols="12"
            cols="6"
            field="max_monthly_mileage"
          >
            <b-form-input
              v-model="resource.max_monthly_mileage"
              placeholder="Liczba kilometrów miesięczna"
            />
          </vs-form-group>

          <vs-form-group
            labelCols="12"
            cols="6"
            field="max_total_mileage"
          >
            <b-form-input
            v-model="resource.max_total_mileage"
            placeholder="Liczba kilometrów całkowita"
          />
          </vs-form-group>
        </div>

        <div
          class="provider-date"
        >
          <vs-form-group
            labelCols="12"
            cols="6"
            field="notice_period"
          >
            <b-form-input
              v-model="resource.notice_period"
              placeholder="Podaj okres wypowiedzenia"
            />
          </vs-form-group>
        </div>
      </template>
    </vs-form-group>

    <vs-form-group
      field="mth"
      :forcedLabel="selectedCategoryReportUnit?.name"
      hideParentFormGroup
      v-if="selectedCategory && selectedCategory.be_reported"
    >
      <template #children>
        <div class="provider-date">
          <vs-form-group
            labelCols="12"
            cols="6"
            field="meter_status_buy"
            :i18nArgs="{ unit: selectedCategoryReportUnit?.name }"
          >
            <b-input-group :append="selectedCategoryReportUnit?.code">
              <b-form-input v-model="resource.meter_status_buy" />
            </b-input-group>
          </vs-form-group>

          <vs-form-group
            labelCols="12"
            cols="6"
            field="meter_status_card_index"
            :i18nArgs="{ unit: selectedCategoryReportUnit?.name }"
          >
            <b-input-group :append="selectedCategoryReportUnit?.code">
              <b-form-input v-model="resource.meter_status_card_index" />
            </b-input-group>
          </vs-form-group>
        </div>
      </template>
    </vs-form-group>

    <vs-form-group field="gps_panel">
      <b-form-input
        v-model="resource.gps_panel"
        placeholder="https://example.org/gps"
      />
    </vs-form-group>

  </vs-form>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import { computed, ref } from 'vue';
import BaseProducerModelPicker from '@/components/module/base/BaseProducerModelPicker.vue';
import BaseStatusPicker from '@/components/module/base/BaseStatusPicker.vue';
import BaseCategoryPicker from '@/components/module/base/BaseCategoryPicker.vue';
import CrmProviderPicker from '@/components/module/crm/CrmProviderPicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import BaseGroupPicker from '@/components/module/base/BaseGroupPicker.vue';
import BaseResourceExtraFieldPicker from '@/components/module/base/BaseResourceExtraFieldPicker.vue';
import BaseTechnologyPicker from '@/components/module/base/BaseTechnologyPicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import store from '@/store';
import { BaseResourceExtraField } from '@/hooks/base/extraFields';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';

export default {
  name: 'BaseResourceForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
    },
  },
  setup(props) {
    store.dispatch('units/fetch');
    const resource = computed(() => props.value);
    const year = `${new Date().getFullYear()}`;

    const baseResourceExtraField = ref(BaseResourceExtraField);
    const selectedCategory = ref(null);
    const selectedGroup = ref(null);

    const excludeOptionByType = (option) => {
      if (option.types) {
        const applicableTypes = option.types.map((type) => type.slug);
        return !applicableTypes.includes(props.type);
      }
      if (option.type?.slug === props.type) return false;
      return true;
    };

    const selectedCategoryReportUnit = computed(() => {
      if (!selectedCategory.value) return null;
      const unitId = selectedCategory.value.unit_id;
      return store.getters['units/byId'](unitId) ?? null;
    });

    return {
      resource,
      year,
      selectedGroup,
      selectedCategory,
      excludeOptionByType,
      selectedCategoryReportUnit,
      baseResourceExtraField,
    };
  },
  components: {
    BInputGroup,
    BFormInput,
    VsForm,
    VsFormGroup,
    BaseProducerModelPicker,
    BaseStatusPicker,
    BaseCategoryPicker,
    CrmProviderPicker,
    VsDatepicker,
    BaseGroupPicker,
    BaseTechnologyPicker,
    CadreEmployeePicker,
    BaseResourceExtraFieldPicker,
    CoreBranchPicker,
  },
};
</script>

<style lang="sass" scoped>
.fuel-card-pin-icon
  display: flex
  align-items: center
  justify-content: center
  padding: 0 15px
  opacity: 0.5

.provider-date
  display: flex
  align-items: flex-start
  gap: 5px

  &::v-deep
    .b-form-group
      width: calc(100% - 5px)
</style>
