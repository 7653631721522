import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchTechnologies } = useBaseApi();

export default defineStore('baseTechnology', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('base.technology', fetchTechnologies);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
