import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchStatusesIndex } = useBaseApi();

export default defineStore('baseStatus', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('base.status', fetchStatusesIndex);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
