<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useBaseProducerModelStore from '@/stores/module/base/producer/useBaseProducerModelStore';

export default Vue.component('BaseProducerModelPicker', {
  emits,
  setup(_, ctx) {
    const store = useBaseProducerModelStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz model z listy',
      reduce: (option) => option.id,
      filter: ['name', 'producer.name'],
      options: store.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => `${option.producer?.name} - ${option.name}`,
      'selected-option': ({ option }) => `${option.producer?.name} - ${option.name}`,
    }));
  },
  components: { VsPickerVue },
});
</script>
