import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchExtraFieldValues } = useBaseApi();

export default defineStore('baseExtraFieldValues', () => {
  const {
    state, fetch, fetched, getById,
  } = useRemoteResource(
    'base.extra_field_values',
    fetchExtraFieldValues,
  );

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
